import { flexRender } from "@tanstack/react-table";
import identity from "lodash/identity";
import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import {
  CheckboxFilter,
  convertStringArrayToCheckboxOption,
  DateRangeFilter,
  DateRangeFilterTypes,
  FilterGroup,
  FilterMenu,
} from "@/components/filters";
import { Header } from "@/components/header";
import { ImpersonationBanner } from "@/components/impersonationBanner";
import { TableHeader } from "@/components/table/tableHeader";
import { TableSharedWrapper } from "@/components/table/views/table-shared-wrapper";
import { useUserContext } from "@/context/user.context";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useAllScreenersQuery } from "@/hooks/queries/screener.queries";
import { useTableHelper } from "@/hooks/use-table-helper.hook";
import { useUserLocalStorageState } from "@/hooks/use-user-local-storage-state.hook";
import { type Screener, ScreenerLocationStatus } from "@/types/screeners";
import {
  formatScreenerColumns,
  getScreenerPinningStyles,
} from "@/utils/columns";
import {
  ALL_SCREENER_PREFIX,
  SEARCH_LOCAL_STORAGE_BASE_KEY,
} from "@/utils/local-storage";

export const ScreenersTable = () => {
  const router = useRouter();
  const track = useTrackAnalytics();
  const { user } = useUserContext();
  const {
    data: tableDataResponse,
    isLoading,
    isPending,
  } = useAllScreenersQuery();
  const { columns, tableData } = useMemo(
    () => ({
      columns: formatScreenerColumns(tableDataResponse?.columns ?? []),
      tableData: tableDataResponse?.screenerRows ?? [],
    }),
    [tableDataResponse],
  );
  const [search, setSearch, searchInitialized] =
    useUserLocalStorageState<string>(
      `${ALL_SCREENER_PREFIX}${SEARCH_LOCAL_STORAGE_BASE_KEY}`,
      "",
    );

  const handleSearch = useCallback(
    (searchValue: string, originalData: Screener[]) => {
      const includesValue = (property?: string) =>
        property?.toLowerCase().includes(searchValue.toLowerCase());

      const includesLocation = (location?: string[]) =>
        location?.some((loc) => includesValue(loc));

      return originalData.filter(
        ({ internalName, jobTitle, location }) =>
          includesValue(internalName) ||
          includesValue(jobTitle) ||
          includesLocation(location),
      );
    },
    [],
  );

  const {
    clearAllFilters,
    dateRange,
    filters,
    lastSubmissionDateRange,
    loadingFallback,
    pagination,
    setDateRange,
    setFilters,
    setLastSubmissionDateRange,
    setPagination,
    table,
  } = useTableHelper<Screener>({
    columns,
    columnPinning: {
      left: ["jobTitle"],
      right: [],
    },
    defaultSortingColumn: "lastSubmissionDate",
    handleSearch,
    isLoading: isLoading || isPending,
    localStoragePrefix: ALL_SCREENER_PREFIX,
    search: searchInitialized ? search : "",
    tableData,
  });

  const rows = table.getFilteredRowModel().rows;
  const jobTitles = Array.from(
    new Set(tableData.map((row) => row.jobTitle)),
  ).sort();

  const locations = Array.from(
    new Set(tableData.flatMap((row) => row.location)),
  )
    // Filtering out bad/old data where location might be null or undefined
    .filter(identity)
    .sort();

  return (
    loadingFallback || (
      <div className="w-full h-dvh">
        <Header />
        <div>
          <TableHeader breadCrumbs={[{ text: "All Screeners" }]} />
          <TableSharedWrapper
            filters={
              <FilterMenu
                clearAllFilters={() => {
                  setSearch("");
                  clearAllFilters();
                }}
                onSearch={setSearch}
                filters={filters}
                searchValue={search}
                rowCount={rows.length}
                searchCountName="screeners"
              >
                <FilterGroup title="Status">
                  <CheckboxFilter
                    analyticsName={"Session status"}
                    key={"status"}
                    name={"status"}
                    options={convertStringArrayToCheckboxOption([
                      ScreenerLocationStatus.Live,
                      ScreenerLocationStatus.Mixed,
                      ScreenerLocationStatus.Paused,
                    ])}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </FilterGroup>
                <FilterGroup title="Last submission date">
                  <DateRangeFilter
                    dateRange={lastSubmissionDateRange}
                    filters={filters}
                    setDateRange={setLastSubmissionDateRange}
                    setFilters={setFilters}
                    type={DateRangeFilterTypes.LastSubmissionDate}
                  />
                </FilterGroup>
                <FilterGroup title="Date created">
                  <DateRangeFilter
                    dateRange={dateRange}
                    filters={filters}
                    setDateRange={setDateRange}
                    setFilters={setFilters}
                    type={DateRangeFilterTypes.CreatedAt}
                  />
                </FilterGroup>
                <FilterGroup title="Job Title">
                  <CheckboxFilter
                    analyticsName={"Job Title"}
                    key={"jobTitle"}
                    name={"jobTitle"}
                    options={convertStringArrayToCheckboxOption(jobTitles)}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </FilterGroup>
                <FilterGroup title="Location">
                  <CheckboxFilter
                    analyticsName={"Location"}
                    key={"location"}
                    name={"location"}
                    options={convertStringArrayToCheckboxOption(locations)}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </FilterGroup>
              </FilterMenu>
            }
            getPinningStyles={getScreenerPinningStyles}
            pagination={pagination}
            setPagination={setPagination}
            table={table}
          >
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={twMerge(
                  "border-b",
                  "border-uw-gray-2",
                  "cursor-pointer",
                  "hover:brightness-95",
                )}
                data-testid={row.original.id}
                onClick={() => {
                  track("Screener Clicked", {
                    screener_id: row.original.id,
                  });
                  router.push(`/screener/${row.original.id}`);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="p-3 w-max text-sm"
                    style={{
                      ...getScreenerPinningStyles(cell.column, row),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </TableSharedWrapper>
        </div>
        {user?.internal && <ImpersonationBanner />}
      </div>
    )
  );
};
