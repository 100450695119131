import { createTheme, CSSVariablesResolver } from "@mantine/core";
import { Poppins } from "next/font/google";

import {
  grays,
  mintGreen,
  purple,
  red,
  skyBlue,
  yellow,
} from "../../tailwind.config";

// Mantine requires 10 shades per color and does not recognize our arrays of 10 strings as the specific type necessary
type MantineColor = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  ...string[],
];

// Each var color is the original from Figma. The rest were generated via
// Mantine's color generator: https://v7.mantine.dev/colors-generator
const colors: { [key: string]: MantineColor } = {
  "mint-green": [
    "#e7fffa",
    "#b9e7df",
    "#a9f4e8",
    "#7cefdb",
    "#59ead0",
    "#44e7ca",
    "#36e6c6",
    "#26cbae",
    mintGreen,
    "#009d85",
    "#0F7C6A",
    "#0f6b5c",
  ],
  yellow: [
    "#fffae1",
    "#fef3cc",
    "#fce79d",
    "#f8da69",
    yellow,
    "#f5c722",
    "#f4c30f",
    "#d9ac00",
    "#c19900",
    "#a78300",
  ],
  red: [
    "#ffe9e9",
    "#ffd2d2",
    "#faa1a2",
    red,
    "#f14343",
    "#f02827",
    "#f01919",
    "#d60a0d",
    "#bf020a",
    "#a80005",
    "#922317",
  ],
  purple: [
    "#f4ecff",
    "#e3d7fb",
    "#c2aeee",
    "#a081e3",
    "#835bd9",
    purple,
    "#6737d2",
    "#5729ba",
    "#4c24a8",
    "#421c94",
  ],
  "sky-blue": [
    "#e2fbff",
    "#d0f2fd",
    "#a4e2f6",
    skyBlue,
    "#4fc4e9",
    "#36bbe6",
    "#23b7e5",
    "#0aa0cc",
    "#008fb7",
    "#007da2",
  ],
  gray: grays as unknown as MantineColor,
};

export const mantineTheme = createTheme({
  colors,
  fontSizes: {
    xs: "0.766rem",
  },
  primaryColor: "purple",
  primaryShade: 5,
});

export const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "400", "500", "700", "900"],
});

export const themeVariablesResolver: CSSVariablesResolver = () => ({
  variables: {
    "--mantine-font-family": poppins.style.fontFamily,
    "--uw-blue": skyBlue,
    "--uw-green": mintGreen,
    "--uw-purple": purple,
    "--uw-red": red,
    "--uw-yellow": yellow,
  },
  light: {},
  dark: {},
});
