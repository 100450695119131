import type { UIEvent } from "react";

import { ScreenerLocationStatus } from "@/types/screeners";
import type { Nullable } from "@/types/utils";

export const MANTINE_COLORS = {
  blue: "var(--uw-blue)",
  green: "var(--uw-green)",
  primary: "var(--uw-purple)",
  red: "var(--uw-red)",
  yellow: "var(--uw-yellow)",
};

enum Rating {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export const getBadgeColor = (value: string, type: string) => {
  const badgeColors: {
    [key: string]: {
      [key: string]: {
        background: string;
        text: string;
      };
    };
  } = {
    qualifyingPassRate: {
      high: { background: "mint-green.1", text: "mint-green.11" },
      low: { background: "#f5c9c7", text: "red.10" },
    },
    overallFit: {
      [Rating.High]: { background: "mint-green.1", text: "mint-green.11" },
      [Rating.Medium]: { background: "#fde68a", text: "red.4" },
      [Rating.Low]: { background: "#f5c9c7", text: "red.10" },
    },
    competency: {
      [Rating.High]: { background: "mint-green.9", text: "mint-green.9" },
      [Rating.Medium]: { background: "#e3573d", text: "#e3573d" },
      [Rating.Low]: { background: "red.4", text: "red.4" },
    },
    isVisibleOnPdf: {
      default: {
        background: "gray.1",
        text: "gray.7",
      },
    },
    status: {
      [ScreenerLocationStatus.Live]: {
        background: "mint-green.2",
        text: "mint-green.10",
      },
      [ScreenerLocationStatus.Mixed]: {
        background: "#E9F7F5",
        text: "#7CA6A0",
      },
      [ScreenerLocationStatus.Paused]: {
        background: "gray.0",
        text: "gray.5",
      },
    },
  };

  if (type === "qualifyingPassRate") {
    if (value === "N/A") {
      return badgeColors.status[ScreenerLocationStatus.Paused];
    }
    return Number(value) >= 75
      ? badgeColors.qualifyingPassRate.high
      : badgeColors.qualifyingPassRate.low;
  }

  if (type.startsWith("isVisibleOnPdf")) {
    return badgeColors.isVisibleOnPdf.default;
  }

  return badgeColors[type]?.[value] || {};
};

export const rowColorCodes = {
  red: "#ffd6d4",
  orange: "#ffedd6",
  yellow: "#ffffdc",
  green: "#d6e8d2",
  blue: "#ceceff",
  purple: "#e9d1e7",
};
export const borderColorCodes = {
  red: "#ff0000",
  orange: "#ffa500",
  yellow: "#ffff00",
  green: "#008000",
  blue: "#0000ff",
  purple: "#800080",
};

export const getJobNameWithDisplayName = (
  jobName?: string,
  internalName?: string,
) =>
  jobName || internalName
    ? `${jobName}${internalName ? ` | ${internalName}` : ""}`
    : "";

export const stopPropagationWrapper =
  <T extends UIEvent>(callback?: (event: T) => void) =>
  (e: T) => {
    e.stopPropagation();

    callback?.(e);
  };

// The actual color values have changed over time. Rather than migrate them all
// in the DB anytime there is a change we can just keep a map of generic names
// to current color codes.
const HIGHLIGHT_COLOR_MAP = {
  blue: MANTINE_COLORS.blue,
  green: MANTINE_COLORS.green,
  orange: "#d97601",
  purple: MANTINE_COLORS.primary,
  red: "var(--mantine-color-red-8)",
  yellow: MANTINE_COLORS.yellow,
} as const;

export type Colors = Nullable<keyof typeof HIGHLIGHT_COLOR_MAP>;

export const getCodeForHighlightColor = (color?: Colors) =>
  color ? HIGHLIGHT_COLOR_MAP[color] : "transparent";

export const HIGHLIGHT_OPTIONS: {
  value: Colors;
  label: string;
}[] = [
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: null, label: "None" },
] as const;

export const getNameForHighlightColor = (colorCode: string) => {
  const mapName = Object.keys(HIGHLIGHT_COLOR_MAP).find(
    (colorName) =>
      HIGHLIGHT_COLOR_MAP[colorName as keyof typeof HIGHLIGHT_COLOR_MAP] ===
      colorCode,
  );
  const accessibleName = HIGHLIGHT_OPTIONS.find(
    ({ value }) => value === mapName,
  );

  return accessibleName?.label ?? "Unknown color name";
};

export const LAYOUT_DIMENSIONS = {
  FILTER_MENU_WIDTH_PX: 224,
  HEADER_HEIGHT_PX: 50,
  IMPERSONATION_BANNER_HEIGHT_PX: 48,
  TABLE_HEADER_HEIGHT_PX: 48,
  get combinedHeaderHeight() {
    return this.HEADER_HEIGHT_PX + this.TABLE_HEADER_HEIGHT_PX;
  },
  get tableViewContentHeight() {
    return `calc(100dvh - ${this.combinedHeaderHeight}px)`;
  },
  get internalUserTableViewContentHeight() {
    return `calc(100dvh - ${this.combinedHeaderHeight + this.IMPERSONATION_BANNER_HEIGHT_PX}px)`;
  },
};

export const getTableViewContentHeight = (isInternal = false) =>
  isInternal
    ? LAYOUT_DIMENSIONS.internalUserTableViewContentHeight
    : LAYOUT_DIMENSIONS.tableViewContentHeight;
