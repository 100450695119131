import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, Pagination, Text } from "@mantine/core";
import {
  type Column,
  flexRender,
  type Row,
  type Table,
} from "@tanstack/react-table";
import Image from "next/image";
import Link from "next/link";
import {
  type CSSProperties,
  type Dispatch,
  type PropsWithChildren,
  type ReactNode,
  type SetStateAction,
  useMemo,
} from "react";
import { twMerge } from "tailwind-merge";

import { useUserContext } from "@/context/user.context";
import type { Screener, ScreenerLocation, Screening } from "@/types/screeners";
import { columnSizingHandler } from "@/utils/columns";
import { getTableViewContentHeight, LAYOUT_DIMENSIONS } from "@/utils/ui";

type TableDataType = Screener | ScreenerLocation | Screening;
interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

interface TableSharedWrapperProps<T extends TableDataType> {
  filters: ReactNode;
  getPinningStyles: (column: Column<T>, row?: Row<T>) => CSSProperties;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  table: Table<T>;
}

const PAGINATION_HEIGHT_PX = 64;

export const TableSharedWrapper = <T extends TableDataType>({
  children,
  filters,
  getPinningStyles,
  pagination,
  setPagination,
  table,
}: PropsWithChildren<TableSharedWrapperProps<T>>) => {
  const { user } = useUserContext();
  const rows = table.getFilteredRowModel().rows;
  const showPagination = rows.length > 1;
  const tableContainerMaxHeight = useMemo(
    () =>
      LAYOUT_DIMENSIONS.combinedHeaderHeight +
      (user?.internal ? LAYOUT_DIMENSIONS.IMPERSONATION_BANNER_HEIGHT_PX : 0) +
      (showPagination ? PAGINATION_HEIGHT_PX : 0),
    [showPagination, user],
  );

  return (
    <div className="flex w-full">
      {filters}
      <div
        style={{
          width: `calc(100dvw - ${LAYOUT_DIMENSIONS.FILTER_MENU_WIDTH_PX}px)`,
        }}
      >
        <div
          className="overflow-scroll w-full"
          style={{
            maxHeight: `calc(100dvh - ${tableContainerMaxHeight}px)`,
          }}
        >
          {rows.length < 1 ? (
            <Flex
              align="center"
              className={twMerge(["w-full"])}
              direction="column"
              justify="center"
              style={{ height: getTableViewContentHeight(user?.internal) }}
            >
              <Text className="text-uw-gray-9" mb={8} size="sm">
                No candidates have interviewed for this location yet.
              </Text>
              <Button
                className="uppercase"
                component={Link}
                href="/"
                leftSection={<FontAwesomeIcon icon={faArrowLeft} />}
                variant="transparent"
              >
                View All Screeners
              </Button>
            </Flex>
          ) : (
            <>
              <div className="h-1" />
              <table className="w-full min-w-max border-l border-r border-gray-200 text-sm">
                <thead className="align-start">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className="shadow-[0_1px_0_0_rgba(217,217,217,1)] sticky top-0 z-50"
                    >
                      {headerGroup.headers.map((header) => (
                        <th
                          colSpan={header.colSpan}
                          className="p-3 text-start uppercase font-semibold text-xs truncate"
                          key={header.id}
                          ref={(th) =>
                            columnSizingHandler(th, table, header.column)
                          }
                          style={getPinningStyles(header.column)}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              className={
                                header.column.getCanSort()
                                  ? "cursor-pointer select-none flex items-center gap-2"
                                  : ""
                              }
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {header.column.getIsSorted()
                                ? {
                                    asc: (
                                      <div className="w-full flex items-center">
                                        <Image
                                          src="/assets/SortAscending.svg"
                                          width={12}
                                          height={12}
                                          alt="sort"
                                        />
                                      </div>
                                    ),
                                    desc: (
                                      <div className="w-full flex items-center">
                                        <Image
                                          src="/assets/SortDescending.svg"
                                          width={12}
                                          height={12}
                                          alt="sort"
                                        />
                                      </div>
                                    ),
                                  }[header.column.getIsSorted() as string]
                                : header.column.getCanSort() && (
                                    <div className="w-full flex items-center">
                                      <Image
                                        src="/assets/sort.svg"
                                        width={12}
                                        height={12}
                                        alt="sort"
                                      />
                                    </div>
                                  )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>{children}</tbody>
              </table>
            </>
          )}
        </div>
        {showPagination && (
          <div
            className="w-full flex justify-center items-center"
            style={{ height: PAGINATION_HEIGHT_PX }}
          >
            <Pagination
              total={Math.ceil(rows.length / pagination.pageSize)}
              onChange={(value) =>
                setPagination({
                  pageSize: pagination.pageSize,
                  pageIndex: value - 1,
                })
              }
              value={pagination.pageIndex + 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};
