"use client";

import { LAYOUT_DIMENSIONS } from "@/utils/ui";
import { Button, Text } from "@mantine/core";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type ColumnFiltersState } from "@tanstack/react-table";
import Link from "next/link";
import { type ReactNode, useCallback } from "react";
import { twMerge } from "tailwind-merge";

import { getSessionCsv } from "@/app/api/screeners";
import { UniversalSearch } from "@/components/universal-search";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import type { CandidateResponseScreener } from "@/types/screeners";

interface TableHeaderProps {
  addScreenerLocationButton?: ReactNode;
  breadCrumbs: {
    href?: string;
    text: string;
  }[];
  filters?: ColumnFiltersState;
  screener?: CandidateResponseScreener;
  screenerLocationId?: string;
}

export const TableHeader = ({
  addScreenerLocationButton,
  breadCrumbs,
  filters,
  screener,
  screenerLocationId,
}: TableHeaderProps) => {
  const track = useTrackAnalytics();

  const downloadCsv = useCallback(async () => {
    if (!screener || !screenerLocationId) {
      return;
    }
    track("CSV Download Clicked", { all_filters_applied: filters });

    const csvData = await getSessionCsv(screenerLocationId);
    const blob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute(
      "download",
      `${screener.name}-${new Date().toISOString()}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [filters, screener, screenerLocationId, track]);

  return (
    <div
      className={twMerge([
        "w-full",
        "px-4",
        "py-2",
        "flex",
        "justify-between",
        "items-center",
        "border-b",
        "border-gray-200",
      ])}
      style={{ height: LAYOUT_DIMENSIONS.TABLE_HEADER_HEIGHT_PX }}
    >
      <div className="flex">
        {breadCrumbs.map(({ href, text }, index) => {
          const content = (
            <>
              {index !== 0 && (
                <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
              )}
              {text}
            </>
          );

          if (href) {
            return (
              <Link
                href={href}
                key={href}
                className="text-uw-purple font-semibold text-xs tracking-wider uppercase"
              >
                {content}
              </Link>
            );
          }

          return (
            <Text
              className="text-uw-gray-5 tracking-wider"
              fw={600}
              key={text}
              size="xs"
              tt="uppercase"
            >
              {content}
            </Text>
          );
        })}
      </div>
      <div className="flex items-center">
        {addScreenerLocationButton}
        {screener && (
          <Button
            className="tracking-wider uppercase"
            disabled={!screener || !screenerLocationId}
            fullWidth
            onClick={downloadCsv}
            rightSection={<FontAwesomeIcon icon={faDownload} />}
            size="xs"
            styles={{
              root: {
                display: "flex",
                justifyContent: "space-between",
                padding: "0 12px",
              },
              label: {
                flex: 1,
                textAlign: "left",
              },
            }}
            variant="transparent"
          >
            Download
          </Button>
        )}
        <UniversalSearch />
      </div>
    </div>
  );
};
