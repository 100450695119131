import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Combobox, Flex, Text, useCombobox } from "@mantine/core";
import type { CellContext } from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";

import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useAssignUserToLocationMutation } from "@/hooks/queries/screener-locations.queries";
import { useUsersQuery } from "@/hooks/queries/user.queries";
import type { ScreenerLocation } from "@/types/screeners";
import type { UserListUser } from "@/types/user";
import type { Nullable } from "@/types/utils";
import { stopPropagationWrapper } from "@/utils/ui";

interface AssignUserCellProps {
  info: CellContext<ScreenerLocation, Nullable<UserListUser>>;
}

export const AssignUserCell = ({ info }: AssignUserCellProps) => {
  const track = useTrackAnalytics();
  const { data: users = [] } = useUsersQuery();
  const assignedUser = info.getValue();
  const { mutate: assignUser } = useAssignUserToLocationMutation();
  const combobox = useCombobox();

  return (
    <Combobox
      offset={6}
      onOptionSubmit={(value) => {
        combobox.closeDropdown();

        // Avoid sending calls if the selected value is already selected.
        if (
          (assignedUser && assignedUser.id === value) ||
          (!assignedUser && !value)
        ) {
          return;
        }

        const locationId = info.row.original.id;
        const userId = value || null;

        track("Assign Recruiter", {
          location_id: locationId,
          user_id: userId,
        });
        assignUser({
          locationId,
          userId,
        });
      }}
      position="bottom-start"
      radius={6}
      store={combobox}
      transitionProps={{ duration: 300, transition: "pop-top-left" }}
      width="max-content"
    >
      <Combobox.Target>
        <Button
          className={twMerge([
            "bg-uw-gray-1",
            "font-bold",
            "px-2",
            "py-0.5",
            "rounded-full",
            "text-uw-gray-7",
            "text-xs",
            "uppercase",
          ])}
          classNames={{ section: "ml-1.5" }}
          onClick={stopPropagationWrapper(() => combobox.toggleDropdown())}
          rightSection={<FontAwesomeIcon icon={faCaretDown} />}
          unstyled
        >
          {assignedUser?.fullName ?? assignedUser?.email ?? "Select..."}
        </Button>
      </Combobox.Target>

      <Combobox.Dropdown className="overflow-y-auto" mah={330} p={0}>
        <Combobox.Options>
          <Combobox.Option
            h={48}
            onClick={stopPropagationWrapper()}
            pb={0}
            pt={6}
            px={18}
            value={""}
          >
            <Flex direction="column" h="100%" justify="center">
              <Text className="text-uw-gray-9" size="xs">
                None
              </Text>
            </Flex>
            <div className="bg-uw-gray-0 h-[1px] w-full" />
          </Combobox.Option>
          {users.map(({ email, fullName, id }) => (
            <Combobox.Option
              key={id}
              onClick={stopPropagationWrapper()}
              px={18}
              value={id}
            >
              <Flex direction="column" h={48} justify="center" key={id}>
                <Text
                  className={twMerge("text-uw-gray-9", fullName && "italic")}
                  size="xs"
                >
                  {fullName ?? "No name provided"}
                </Text>
                <Text className="text-uw-gray-5" fz={11}>
                  {email}
                </Text>
              </Flex>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
