"use client";

import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type PropsWithChildren } from "react";

import { mantineTheme, themeVariablesResolver } from "@/app/mantine-theme";
import { ExtensionProvider } from "@/components/extension-provider";
import { SegmentContextProvider } from "@/context/segment.context";
import { UserContextProvider } from "@/context/user.context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // 15 minutes
      staleTime: 15 * 60 * 1000,
    },
  },
});

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        cssVariablesResolver={themeVariablesResolver}
        theme={mantineTheme}
      >
        <UserContextProvider>
          <SegmentContextProvider>
            <ExtensionProvider>{children}</ExtensionProvider>
          </SegmentContextProvider>
        </UserContextProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};
